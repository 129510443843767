<template>
  <el-row type="flex" justify="space-between">
    <el-col class="u_p--l10 u_p--r10" :span="24">
      <div class="static-container animated fadeIn info">
        <div class="static-container-header static-container-header--small">
          <h5 class="static-container-header__title">
            <div class="static-container-header__left">
              {{ $t('tasklist__title') }}
            </div>
            <div class="static-container-header__right"></div>
          </h5>
        </div>
      </div>
      <div class="static-container-body">
        <div class="static-container-header static-container-header--small">
          <h5 class="static-container-header__title flex">
            <button class="btn_search" @click="Searchuserlist()">{{ $t('btn_search') }}</button>
            <button class="btn_add" @click="goDetail()">{{ $t('btn_add') }}</button>
          </h5>
        </div>
      </div>
    </el-col>
    <el-col class="u_p--l10 u_p--r10" :span="24">
      <custom-table
        :titleList="titleList"
        :list="showList"
        :table="table"
        :pagination="pagination"
        @change="handleChange"
        :loading="tableLoading"
      >
        <template v-slot:default="{item: {key, item}}">
          <div class="" v-if="key === 'other'">
            <span
              :key="'opt_detail' + i"
              class="u_c--pointer u_p--relative u_p--l5 u_underline j_txt--blue"
              @click="handleTrClick(item)"
            >
              <img
                width="30px"
                height="30px"
                src="@/assets/web/image/userlist/user-detail.svg"
                :alt="$t('ui_detail')"
              />
            </span>
            <span
              v-if="item.review === 2"
              :key="'opt' + i"
              class="u_c--pointer u_p--relative u_p--l5 u_underline j_txt--blue"
              @click="goDetail(item)"
            >
              <img
                width="30px"
                height="30px"
                src="@/assets/web/image/edit.svg"
                :alt="$t('ui_transfer')"
              />
            </span>
          </div>
          <span v-if="key === 'title'">
            {{ getTitle(item.title) }}
          </span>
          <!--          <span v-if="key === 'source'">-->
          <!--            {{getSource(item.source)}}-->
          <!--          </span>-->
          <span v-if="key === 'hot'">
            {{ getHot(item.hot) }}
          </span>
          <span v-if="key === 'review'">
            {{ getReview(item.review) }}
          </span>
          <span v-if="key === 'status'">
            {{ getState(item.status) }}
          </span>
          <span v-if="key === 'platform'">
            {{ getPlatform(item.platform) }}
          </span>
        </template>
      </custom-table>
    </el-col>
  </el-row>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {App, Server, Storage} from '@/services';
import {ElCol, ElRow, ElSelect, ElMessageBox, ElMessage} from 'element-plus';
import {Itasklist, IPage, Itask} from '@/model/taskList';
import CustomTable from '@/common/web/table/index.vue';
import eventBus from '@/web/eventBus';
import Tips from '@/common/web/tips/index.vue';
import {basic} from '@/config';

interface IListItem {
  id: string;
  number_limit: string;
  money: string;
  sort: string;
  rule: string;
  type: number;
  teach_image: string;
  teach_video: string;
}

@Options({
  components: {
    ElCol,
    ElRow,
    ElSelect,
    CustomTable,
    ElMessageBox,
    ElMessage,
    Tips
  },
  mounted() {
    this.userinfo = App.getUserinfo();
    this.nowLang = Storage.get('language') || 'zh-CN';
    eventBus.bus$on('langChange', () => {
      // this.Searchuserlist(this.pagination.page);
      this.initPage();
    });
  },
  created() {
    this.initPage();
  },
  methods: {
    async initPage() {
      await this.loginTask();
      await this.Searchuserlist();
    },
    async loginTask() {
      const data = await Server.Web.Task.taskLogin<any>({
        userName: this.userInfo.userAccount,
        userId: this.userInfo.userId
      });
      if (data.token) {
        localStorage.setItem('mainland_token', data.token);
      } else {
        throw new Error('未能获取到token');
      }
    },
    async Searchuserlist() {
      this.tableLoading = true;
      const params = {
        page: this.pagination.page,
        size: this.pagination.pageLimit
      };
      const data = await Server.Web.Task.tasklist<Itasklist>(params);
      if (data instanceof Error) {
        ElMessage({
          type: 'error',
          message: data.message
        });
      } else {
        this.list = data.data || [];
        this.pagination = {
          ...this.pagination,
          totalNumber: data.total,
          totalPage: Math.ceil(data.total / this.pagination.pageLimit)
        };
        this.showList = [...this.list];
        // alert(this.list.length);
      }
      this.tableLoading = false;
      setTimeout(() => {
        this.isLoading = false;
        this.tableLoading = false;
      });
    },
    handleChange(item: {limit: number; page: number}) {
      this.pagination.page = item.page;
      this.pagination.pageLimit = item.limit;
      this.Searchuserlist();
    },
    async handleTrClick(item: IListItem) {
      ElMessageBox({
        title: '',
        message: `<div class="detailItem">${this.$t('task_ID')}：${item.id}</div>
                  <div class="detailItem">${this.$t('task_type')}：${this.getType(item.type)}</div>
                  <div class="detailItem">${this.$t('task_quantity_restriction')}：${
          item.number_limit
        }</div>
                  <div class="detailItem">${this.$t('task_get_amount')}：${item.money}</div>
                  <div class="detailItem">${this.$t('task_priority')}：${item.sort}</div>
                  <div class="detailItem">${this.$t('task_rule')}：${this.getTitle(item.rule)}</div>
                  <div class="detailItem">${this.$t('task_teaching_picture')}：<a href="${
          basic.mainlandUrl
        }/${this.getTitle(item.teach_image)}" target="_blank">${this.getTitle(
          item.teach_image
        )}</a></div>
                  <div class="detailItem">${this.$t('task_teaching_video')}：<a href="${
          basic.mainlandUrl
        }/${this.getTitle(item.teach_video)}" target="_blank">${this.getTitle(
          item.teach_video
        )}</a></div>`,
        dangerouslyUseHTMLString: true
      });

      // await this.handleGetDatail();
    },
    goDetail(item: Itask) {
      this.$router.push({
        name: 'taskDetail',
        params: item
      });
    },
    getType(state: number) {
      const list = [
        {
          label: this.$t('task_type_common'),
          value: 1
        },
        {
          label: this.$t('task_type_holding'),
          value: 2
        },
        {
          label: this.$t('task_type_mass'),
          value: 3
        }
      ];
      return list.find(item => item.value === state)?.label;
    },
    getHot(state: number) {
      const list = [
        {
          label: this.$t('task_yes'),
          value: 0
        },
        {
          label: this.$t('task_no'),
          value: 1
        }
      ];
      return list.find(item => item.value === state)?.label;
    },
    getState(state: number) {
      const list = [
        {
          label: this.$t('task_unpublished'),
          value: 0
        },
        {
          label: this.$t('task_post'),
          value: 1
        }
      ];
      return list.find(item => item.value === state)?.label;
    },
    getReview(review: number) {
      const list = [
        {
          label: this.$t('task_pass'),
          value: 1
        },
        {
          label: this.$t('task_wait_audit'),
          value: 2
        },
        {
          label: this.$t('task_fail'),
          value: 3
        }
      ];
      return list.find(item => item.value === review)?.label;
    },
    getPlatform(state: number) {
      const list = [
        {
          label: 'Facebook',
          value: 1
        },
        {
          label: 'Line',
          value: 2
        }
      ];
      return list.find(item => item.value === state)?.label;
    },
    // 规则,title,图片,视频是同样的规范  因此都可以使用该方法
    getTitle(title: {}) {
      const disposeTitle = Object.entries(title);
      const lang = ['en', 'zh-CN', 'th', 'vn'];
      return disposeTitle[lang.indexOf(this.$i18n.locale)][1];
    }
  },
  computed: {
    table() {
      const list: Array<Itask> = this.showList;
      return {
        list,
        keys: [
          'title',
          // 'source',
          'platform',
          'hot',
          'amount',
          'price',
          'review',
          'status',
          'other'
        ]
      };
    },
    loadmore() {
      return this.pagination.page * this.pagination.pageLimit >= this.pagination.totalNumber;
    }
  }
})
export default class PersonalInformation extends Vue {
  win = 1000;
  list = {};
  showList = [];
  userInfo = App.getUserinfo();
  pagination: IPage = {
    page: 0,
    totalPage: 1,
    pageLimit: 20,
    totalNumber: 0
  };
  titleList = [
    {name: 'task_title', key: 'title'},
    // {name: 'task_source', key: 'source'},
    {name: 'task_platform', key: 'platform'},
    {name: 'task_hot', key: 'hot'},
    {name: 'task_number', key: 'amount'},
    {name: 'task_amount', key: 'price'},
    {name: 'task_audit_type', key: 'review'},
    {name: 'task_status', key: 'status'},
    {name: 'rp_operate', key: 'other'}
  ];
  value = '';
}
</script>
<style lang="scss" scoped>
.u_p--l10 {
  padding-left: 10px;
}

.u_p--r10 {
  padding-right: 10px;
}

.detailItem {
  text-align: left;
  width: 80%;
  margin: 0 auto;
}

.main-btn {
  width: 100%;
  margin: auto;
  height: 1.92rem;
  color: $cf;
  background-color: $bg;
  border-radius: 8px;
  font-size: 0.64rem;
  border-width: 0;
}

.static-container-header {
  margin-bottom: 30px;
}

.static-container-header--small .static-container-header__title {
  font-size: 24px;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.el-form,
.panel {
  max-width: 95%;
}

.el-form-item--small {
  line-height: 20px;

  .el-form-item__content.plain {
    line-height: 38px;
  }
}

.el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #000;
  line-height: 40px;
  padding: 0 6px 0 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.static-container-header__title::before {
  content: none;
  right: 34.3%;
  width: 70.3%;
}

.el-input--medium {
  height: 0.4rem;
  line-height: 0.4rem;
  vertical-align: text-top;
}

.el-form-item__content {
  line-height: 20px;
}

.nowrap {
  white-space: nowrap;
}

.el-button + .el-button {
  margin-left: 0;
}

:deep(.el-date-editor.el-input) {
  width: 340px;

  .el-input__inner::placeholder {
    color: #c0c4cc;
  }

  &.c_input--longest .el-input__inner:valid {
    border-color: transparent;
    color: #666666;
  }

  &.c_input--longest .el-input__inner {
    background-color: #f5f5f5;
  }
}

.reverse {
  display: flex;
  flex-direction: column-reverse;
}

.c_input--longest {
  width: 210px;
  border: 1px solid #c0c4cc;
  border-radius: 8px;
}

.u_m--t50 {
  margin-top: 50px;
}

.u_m--r20 {
  margin-right: 20px;
}

.u_m--r10 {
  margin-right: 10px;
}

.u_m--r12 {
  margin-right: 12px;
}

.u_m--l12 {
  margin-left: 12px;
}

.c_input--longest .el-input__inner:valid {
  border-color: transparent;
  color: #666666;
}

.c_input--longest :deep(.el-input__inner) {
  background-color: #f5f5f5;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.el-input--medium .el-input__inner {
  height: 36px;
  line-height: 36px;
}

.el-input__inner::placeholder {
  color: #c0c4cc;
}

.userbtn-bg--submit {
  background-color: $bg;
  width: calc(100% - 20px);
  font-weight: 700 !important;
}

.userbtn-bg--submit:hover {
  opacity: 0.5;
}

.userbtn-bg--submit:disabled {
  background-color: #e8e8e8;
  background-image: linear-gradient(to left, #e8e8e8, #e8e8e8);
  color: #999999;
  cursor: not-allowed;
}

[class*='c_button']:not(.c_button__text)[class*='--huge'] {
  font-size: 18px;
  line-height: 25px;
  padding: 4px 12px;
}

[class*='c_button']:not(.c_button__text) {
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  font: 14px 'Open Sans', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, 'PingFang SC',
    'Hiragino Sans GB', 'Heiti SC', 'WenQuanYi Micro Hei', sans-serif;
  line-height: 19px;
  overflow: hidden;
  padding: 8px 25px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  -webkit-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}

[class*='c_button']:not(.c_button__text) .c_button__text {
  position: relative;
  z-index: 5;
}

.c_button__text {
  font-size: 12px;
}

.j_btn-txt--primary {
  color: #ffffff;
}

.u_m--l5 {
  margin-left: 5px;
}

.online_cs {
  color: #0047ab;
  text-decoration: underline;
}

.u_c--pointer {
  cursor: pointer;
}

.table-row {
  display: table-row;
  border: solid 1px #ff0000;
}

.table-cell {
  display: table-cell;
  vertical-align: middle;
  padding-top: 8px;
  padding-bottom: 8px;
  white-space: nowrap;
  min-width: 90px;
}

.el-form-item__content {
  line-height: 20px;
  position: relative;
  font-size: 14px;
}

.el-form-item__content::before,
.el-form-item__content::after {
  display: table;
  content: '';
}

.btn_size {
  height: 32px;
  width: 82px;
}

.u_main--btn {
  -webkit-box-shadow: 0px 3px transparent;
  box-shadow: 0px 3px transparent;
  font-size: 0.14rem;
  border-radius: 8px;
  background-color: #1f55f0;
  color: #ffffff;
  cursor: pointer;
}

.u_d--table {
  min-width: 80%;
  display: table;
}

.user-info__form {
  margin-top: 30px;
  width: 95%;
}

.dialog_main {
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog_head {
  font-size: 24px;
  font-weight: 400;
}

.btn_search,
.btn_add {
  font-size: 18px;
  min-width: 120px;
  background-color: #1f55f0;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  line-height: 19px;
  overflow: hidden;
  padding: 8px 25px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  max-height: 40px;
  color: #fff;
  margin-right: 12px;
}

// table
.u_th--bg {
  background-color: inherit;
  color: inherit;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
}

.page_number {
  color: $c3;
}

.c_table {
  overflow: auto;
}

.c_table-fixed {
  z-index: 2;
}

table {
  table-layout: fixed;
  width: 100%;

  td,
  th {
    width: 9vw;
  }

  .th {
    position: relative;
    padding: 10px 0;
  }

  td:first-child,
  th:first-child {
    position: sticky;
    left: 0;
    z-index: 3;
  }

  th:first-child {
    background-image: $bg;
  }

  td:first-child {
    background-color: $bg !important;
    color: $btn-bg !important;
  }
}

.u_c--pointer {
  img {
    padding: 0 1px;
    width: 35px;
    height: 35px;
  }
}
</style>
