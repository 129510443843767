
import {Options, Vue} from 'vue-class-component';
import {App, Server, Storage} from '@/services';
import {ElCol, ElRow, ElSelect, ElMessageBox, ElMessage} from 'element-plus';
import {Iuserlist, Iuser, IPage} from '@/model/memberList';
import CustomTable from '@/common/web/table/index.vue';
import eventBus from '@/web/eventBus';
import Tips from '@/common/web/tips/index.vue';

@Options({
  components: {
    ElCol,
    ElRow,
    ElSelect,
    CustomTable,
    ElMessageBox,
    ElMessage,
    Tips
  },
  mounted() {
    this.userinfo = App.getUserinfo();
    this.nowLang = Storage.get('language') || 'zh-CN';
    eventBus.bus$on('langChange', () => {
      this.Searchuserlist(this.pagination.page);
    });
  },
  created() {
    this.Searchuserlist();
  },
  methods: {
    async Searchuserlist() // val: {type?: string} | number | undefined | string
    {
      this.tableLoading = true;
      const params = {
        page: this.pagination.page,
        page_limit: this.pagination.pageLimit,
        user_name: this.name,
        level: this.downdata.level,
        status: this.downdata.status
      };
      const data = await Server.Web.Common.userlist<Iuserlist>(params);
      if (data instanceof Error) {
        ElMessage({
          type: 'error',
          message: data.message
        });
      } else {
        this.list = data.list || [];
        this.pagination = data.pagination;
        this.showList = [...this.list];
        // alert(this.list.length);
      }
      this.tableLoading = false;
      setTimeout(() => {
        this.isLoading = false;
        this.tableLoading = false;
      });
    },
    handleChange(item: {limit: number; page: number}) {
      this.pagination.page = item.page;
      this.pagination.pageLimit = item.limit;
      this.Searchuserlist();
    },
    async handleTrClick(item: {
      vip_level: string;
      userName: string;
      level: string;
      levelId: string;
      time: string;
      withdraw: string;
      winLose: string;
      statusText: string;
      registerTime: string;
      lastLoginTime: string;
      depositTime: string;
    }) {
      ElMessageBox({
        title: item.userName,
        message:
          '<div style="text-align: left;width: 70%;margin: 0 auto;">' +
          this.$t('ui_reg_time_colon') +
          '  ' +
          item.registerTime +
          '</div> <br/> ' +
          '<div style="text-align: left;width: 70%;margin: 0 auto;">' +
          this.$t('ui_last_login_time_colon') +
          '  ' +
          item.lastLoginTime +
          '</div> <br/> ' +
          '<div style="text-align: left;width: 70%;margin: 0 auto;">' +
          this.$t('ui_text_0085') +
          ':  ' +
          item.depositTime +
          '</div> <br/> ',
        dangerouslyUseHTMLString: true
      });

      // await this.handleGetDatail();
    },
    godeail(item: {
      vip_level: string;
      userName: string;
      level: string;
      levelId: string;
      time: string;
      withdraw: string;
      winLose: string;
      statusText: string;
      registerTime: string;
      lastLoginTime: string;
      depositTime: string;
    }) {
      // this.$router.push({name: 'listdetail', query: {userName: item.userName}});
      this.$router.push({
        name: 'listdetail',
        params: {
          userName: item.userName
        }
      });
    }
  },
  computed: {
    table() {
      const list: Array<{
        vip_level: string;
        userName: string;
        level: string;
        time: string;
        withdraw: string;
        winLose: string;
        statusText: string;
      }> = this.showList;
      return {
        list,
        keys: [
          'vip_level',
          'userName',
          'level',
          'deposit',
          'withdraw',
          'winLose',
          'statusText',
          'other'
        ]
      };
    },
    loadmore() {
      return this.pagination.page * this.pagination.pageLimit >= this.pagination.totalNumber;
    }
  }
})
export default class PersonalInformation extends Vue {
  win = 1000;
  rules = ['ui_text_0084', 'ui_userlist_ps2', 'ui_userlist_ps3'];
  list = {};
  name = '';
  showList = [];
  config = App.getConfig() || {
    dollarSign: '¥'
  };
  userInfo = App.getUserinfo();
  pagination: IPage = {
    page: 0,
    totalPage: 1,
    pageLimit: 20,
    totalNumber: 0
  };
  downdata = {
    level: 0,
    status: 0
  };
  titleList = [
    {name: 'VIP Level', key: 'vip_level'},
    {name: 'rp_username', key: 'userName'},
    {name: 'rp_level', key: 'level'},
    {name: 'btn_dep', key: 'deposit'},
    {name: 'btn_withdraw', key: 'withdraw'},
    {name: 'ui_text_016', key: 'winLose'},
    {name: 'rp_status', key: 'statusText'},
    {name: 'rp_operate', key: 'other'}
  ];
  status = [
    {
      value: 0,
      label: 'user_valid[0]'
    },
    {
      value: 1,
      label: 'user_valid[1]'
    },
    {
      value: 2,
      label: 'user_valid[2]'
    }
  ];
  levels = [
    {
      value: 0,
      label: 'user_valid[0]'
    },
    {
      value: 1,
      label: 'ui_agent'
    },
    {
      value: 2,
      label: 'ui_member'
    }
  ];
  value = '';

  goToLink(name: string) {
    this.$router.push({name});
  }
  showGameRecord(item: Iuser) {
    this.$router.push({
      name: 'MemberGameRecord',
      params: {
        id: item.userId + '-' + item.userName
      }
    });
  }
}
