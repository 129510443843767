
import {Options, Vue} from 'vue-class-component';
import {App, Server, Storage} from '@/services';
import {ElCol, ElRow, ElSelect, ElMessageBox, ElMessage} from 'element-plus';
import {Itasklist, IPage, Itask} from '@/model/taskList';
import CustomTable from '@/common/web/table/index.vue';
import eventBus from '@/web/eventBus';
import Tips from '@/common/web/tips/index.vue';
import {basic} from '@/config';

interface IListItem {
  id: string;
  number_limit: string;
  money: string;
  sort: string;
  rule: string;
  type: number;
  teach_image: string;
  teach_video: string;
}

@Options({
  components: {
    ElCol,
    ElRow,
    ElSelect,
    CustomTable,
    ElMessageBox,
    ElMessage,
    Tips
  },
  mounted() {
    this.userinfo = App.getUserinfo();
    this.nowLang = Storage.get('language') || 'zh-CN';
    eventBus.bus$on('langChange', () => {
      // this.Searchuserlist(this.pagination.page);
      this.initPage();
    });
  },
  created() {
    this.initPage();
  },
  methods: {
    async initPage() {
      await this.loginTask();
      await this.Searchuserlist();
    },
    async loginTask() {
      const data = await Server.Web.Task.taskLogin<any>({
        userName: this.userInfo.userAccount,
        userId: this.userInfo.userId
      });
      if (data.token) {
        localStorage.setItem('mainland_token', data.token);
      } else {
        throw new Error('未能获取到token');
      }
    },
    async Searchuserlist() {
      this.tableLoading = true;
      const params = {
        page: this.pagination.page,
        size: this.pagination.pageLimit
      };
      const data = await Server.Web.Task.tasklist<Itasklist>(params);
      if (data instanceof Error) {
        ElMessage({
          type: 'error',
          message: data.message
        });
      } else {
        this.list = data.data || [];
        this.pagination = {
          ...this.pagination,
          totalNumber: data.total,
          totalPage: Math.ceil(data.total / this.pagination.pageLimit)
        };
        this.showList = [...this.list];
        // alert(this.list.length);
      }
      this.tableLoading = false;
      setTimeout(() => {
        this.isLoading = false;
        this.tableLoading = false;
      });
    },
    handleChange(item: {limit: number; page: number}) {
      this.pagination.page = item.page;
      this.pagination.pageLimit = item.limit;
      this.Searchuserlist();
    },
    async handleTrClick(item: IListItem) {
      ElMessageBox({
        title: '',
        message: `<div class="detailItem">${this.$t('task_ID')}：${item.id}</div>
                  <div class="detailItem">${this.$t('task_type')}：${this.getType(item.type)}</div>
                  <div class="detailItem">${this.$t('task_quantity_restriction')}：${
          item.number_limit
        }</div>
                  <div class="detailItem">${this.$t('task_get_amount')}：${item.money}</div>
                  <div class="detailItem">${this.$t('task_priority')}：${item.sort}</div>
                  <div class="detailItem">${this.$t('task_rule')}：${this.getTitle(item.rule)}</div>
                  <div class="detailItem">${this.$t('task_teaching_picture')}：<a href="${
          basic.mainlandUrl
        }/${this.getTitle(item.teach_image)}" target="_blank">${this.getTitle(
          item.teach_image
        )}</a></div>
                  <div class="detailItem">${this.$t('task_teaching_video')}：<a href="${
          basic.mainlandUrl
        }/${this.getTitle(item.teach_video)}" target="_blank">${this.getTitle(
          item.teach_video
        )}</a></div>`,
        dangerouslyUseHTMLString: true
      });

      // await this.handleGetDatail();
    },
    goDetail(item: Itask) {
      this.$router.push({
        name: 'taskDetail',
        params: item
      });
    },
    getType(state: number) {
      const list = [
        {
          label: this.$t('task_type_common'),
          value: 1
        },
        {
          label: this.$t('task_type_holding'),
          value: 2
        },
        {
          label: this.$t('task_type_mass'),
          value: 3
        }
      ];
      return list.find(item => item.value === state)?.label;
    },
    getHot(state: number) {
      const list = [
        {
          label: this.$t('task_yes'),
          value: 0
        },
        {
          label: this.$t('task_no'),
          value: 1
        }
      ];
      return list.find(item => item.value === state)?.label;
    },
    getState(state: number) {
      const list = [
        {
          label: this.$t('task_unpublished'),
          value: 0
        },
        {
          label: this.$t('task_post'),
          value: 1
        }
      ];
      return list.find(item => item.value === state)?.label;
    },
    getReview(review: number) {
      const list = [
        {
          label: this.$t('task_pass'),
          value: 1
        },
        {
          label: this.$t('task_wait_audit'),
          value: 2
        },
        {
          label: this.$t('task_fail'),
          value: 3
        }
      ];
      return list.find(item => item.value === review)?.label;
    },
    getPlatform(state: number) {
      const list = [
        {
          label: 'Facebook',
          value: 1
        },
        {
          label: 'Line',
          value: 2
        }
      ];
      return list.find(item => item.value === state)?.label;
    },
    // 规则,title,图片,视频是同样的规范  因此都可以使用该方法
    getTitle(title: {}) {
      const disposeTitle = Object.entries(title);
      const lang = ['en', 'zh-CN', 'th', 'vn'];
      return disposeTitle[lang.indexOf(this.$i18n.locale)][1];
    }
  },
  computed: {
    table() {
      const list: Array<Itask> = this.showList;
      return {
        list,
        keys: [
          'title',
          // 'source',
          'platform',
          'hot',
          'amount',
          'price',
          'review',
          'status',
          'other'
        ]
      };
    },
    loadmore() {
      return this.pagination.page * this.pagination.pageLimit >= this.pagination.totalNumber;
    }
  }
})
export default class PersonalInformation extends Vue {
  win = 1000;
  list = {};
  showList = [];
  userInfo = App.getUserinfo();
  pagination: IPage = {
    page: 0,
    totalPage: 1,
    pageLimit: 20,
    totalNumber: 0
  };
  titleList = [
    {name: 'task_title', key: 'title'},
    // {name: 'task_source', key: 'source'},
    {name: 'task_platform', key: 'platform'},
    {name: 'task_hot', key: 'hot'},
    {name: 'task_number', key: 'amount'},
    {name: 'task_amount', key: 'price'},
    {name: 'task_audit_type', key: 'review'},
    {name: 'task_status', key: 'status'},
    {name: 'rp_operate', key: 'other'}
  ];
  value = '';
}
