<template>
  <el-row type="flex" justify="space-between">
    <el-col class="u_p--l10 u_p--r10" :span="24">
      <div class="static-container animated fadeIn info">
        <div class="static-container-header static-container-header--small">
          <h5 class="static-container-header__title">
            <div class="static-container-header__left">
              {{ $t('memberlist__title') }}
            </div>
            <div class="static-container-header__right"></div>
          </h5>
        </div>
      </div>
      <div class="static-container-body">
        <div class="static-container-header static-container-header--small">
          <h5 class="static-container-header__title flex">
            <div class="el-form-item is-flex el-form-item--medium u_w--25p">
              <label class="el-form-item__label">{{ $t('rp_acc') }}</label>
              <div class="el-form-item__content u_m--r10">
                <el-input
                  class="c_input--longest "
                  v-model.trim="name"
                  :placeholder="'&nbsp;' + '&nbsp;' + $t('login__usernamePlaceholder')"
                >
                  <template #prefix><i class="el-icon-search  el-input__icon"></i></template>
                </el-input>
              </div>
            </div>
            <!--  -->
            <div class="u_m--r10">
              <label class="el-form-item__label">{{ $t('rp_level') }}</label>
              <div class="u_flex u_flex--column">
                <el-select
                  v-model="downdata.level"
                  :placeholder="$t('ui_all')"
                  class="c_input--longest"
                >
                  <el-option
                    v-for="item in levels"
                    :key="item.value"
                    :label="$t(item.label)"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="u_m--r10">
              <label class="el-form-item__label">{{ $t('rp_status') }}</label>
              <div class="el-form-item__content">
                <div class="el-select el-select--medium">
                  <el-select
                    class="c_input--longest"
                    v-model="downdata.status"
                    :placeholder="$t('ui_all')"
                  >
                    <el-option
                      v-for="(item, i) in status"
                      :key="i"
                      :label="$t(item.label)"
                      :value="item.value"
                    />
                  </el-select>
                </div>
              </div>
            </div>
            <button class="btn_search" @click="Searchuserlist()">{{ $t('btn_search') }}</button>
          </h5>
        </div>
      </div>
    </el-col>
    <el-col class="u_p--l10 u_p--r10" :span="24">
      <custom-table
        :titleList="titleList"
        :list="showList"
        :table="table"
        :pagination="pagination"
        @change="handleChange"
        :loading="tableLoading"
      >
        <template v-slot:default="{item: {key, item}}">
          <div class="" v-if="key === 'other'">
            <span
              :key="'opt_detail' + i"
              class="u_c--pointer u_p--relative u_p--l5 u_underline j_txt--blue"
              @click="handleTrClick(item)"
            >
              <img
                width="30px"
                height="30px"
                src="@/assets/web/image/userlist/user-detail.svg"
                :alt="$t('ui_detail')"
              />
            </span>
            <span
              :key="'opt' + i"
              class="u_c--pointer u_p--relative u_p--l5 u_underline j_txt--blue"
              @click="godeail(item)"
            >
              <img
                width="30px"
                height="30px"
                src="@/assets/web/image/userlist/user-transfer.svg"
                :alt="$t('ui_transfer')"
              />
            </span>
            <span
              v-if="item.levelId == 0"
              :key="'opt2' + i"
              class="u_c--pointer u_p--relative u_p--l5 u_underline j_txt--blue"
              @click="showGameRecord(item)"
            >
              <img
                width="30px"
                height="30px"
                src="@/assets/web/image/userlist/user-record.svg"
                :alt="$t('ui_text_010')"
              />
            </span>
          </div>
          <span
            v-if="key === 'winLose'"
            :class="[
              {'text-tomato': parseFloat(item.winLose) > 0},
              {'text-grass': parseFloat(item.winLose) < 0}
            ]"
            >{{ item.winLose }}</span
          >
          <span v-if="key === 'statusText'">{{
            item.statusText != '--' ? item.statusText : ''
          }}</span>
        </template>
      </custom-table>
    </el-col>
    <el-col>
      <tips :rules="rules" />
    </el-col>
  </el-row>
</template>

<script lang="ts">
import {Options, Vue} from 'vue-class-component';
import {App, Server, Storage} from '@/services';
import {ElCol, ElRow, ElSelect, ElMessageBox, ElMessage} from 'element-plus';
import {Iuserlist, Iuser, IPage} from '@/model/memberList';
import CustomTable from '@/common/web/table/index.vue';
import eventBus from '@/web/eventBus';
import Tips from '@/common/web/tips/index.vue';

@Options({
  components: {
    ElCol,
    ElRow,
    ElSelect,
    CustomTable,
    ElMessageBox,
    ElMessage,
    Tips
  },
  mounted() {
    this.userinfo = App.getUserinfo();
    this.nowLang = Storage.get('language') || 'zh-CN';
    eventBus.bus$on('langChange', () => {
      this.Searchuserlist(this.pagination.page);
    });
  },
  created() {
    this.Searchuserlist();
  },
  methods: {
    async Searchuserlist() // val: {type?: string} | number | undefined | string
    {
      this.tableLoading = true;
      const params = {
        page: this.pagination.page,
        page_limit: this.pagination.pageLimit,
        user_name: this.name,
        level: this.downdata.level,
        status: this.downdata.status
      };
      const data = await Server.Web.Common.userlist<Iuserlist>(params);
      if (data instanceof Error) {
        ElMessage({
          type: 'error',
          message: data.message
        });
      } else {
        this.list = data.list || [];
        this.pagination = data.pagination;
        this.showList = [...this.list];
        // alert(this.list.length);
      }
      this.tableLoading = false;
      setTimeout(() => {
        this.isLoading = false;
        this.tableLoading = false;
      });
    },
    handleChange(item: {limit: number; page: number}) {
      this.pagination.page = item.page;
      this.pagination.pageLimit = item.limit;
      this.Searchuserlist();
    },
    async handleTrClick(item: {
      vip_level: string;
      userName: string;
      level: string;
      levelId: string;
      time: string;
      withdraw: string;
      winLose: string;
      statusText: string;
      registerTime: string;
      lastLoginTime: string;
      depositTime: string;
    }) {
      ElMessageBox({
        title: item.userName,
        message:
          '<div style="text-align: left;width: 70%;margin: 0 auto;">' +
          this.$t('ui_reg_time_colon') +
          '  ' +
          item.registerTime +
          '</div> <br/> ' +
          '<div style="text-align: left;width: 70%;margin: 0 auto;">' +
          this.$t('ui_last_login_time_colon') +
          '  ' +
          item.lastLoginTime +
          '</div> <br/> ' +
          '<div style="text-align: left;width: 70%;margin: 0 auto;">' +
          this.$t('ui_text_0085') +
          ':  ' +
          item.depositTime +
          '</div> <br/> ',
        dangerouslyUseHTMLString: true
      });

      // await this.handleGetDatail();
    },
    godeail(item: {
      vip_level: string;
      userName: string;
      level: string;
      levelId: string;
      time: string;
      withdraw: string;
      winLose: string;
      statusText: string;
      registerTime: string;
      lastLoginTime: string;
      depositTime: string;
    }) {
      // this.$router.push({name: 'listdetail', query: {userName: item.userName}});
      this.$router.push({
        name: 'listdetail',
        params: {
          userName: item.userName
        }
      });
    }
  },
  computed: {
    table() {
      const list: Array<{
        vip_level: string;
        userName: string;
        level: string;
        time: string;
        withdraw: string;
        winLose: string;
        statusText: string;
      }> = this.showList;
      return {
        list,
        keys: [
          'vip_level',
          'userName',
          'level',
          'deposit',
          'withdraw',
          'winLose',
          'statusText',
          'other'
        ]
      };
    },
    loadmore() {
      return this.pagination.page * this.pagination.pageLimit >= this.pagination.totalNumber;
    }
  }
})
export default class PersonalInformation extends Vue {
  win = 1000;
  rules = ['ui_text_0084', 'ui_userlist_ps2', 'ui_userlist_ps3'];
  list = {};
  name = '';
  showList = [];
  config = App.getConfig() || {
    dollarSign: '¥'
  };
  userInfo = App.getUserinfo();
  pagination: IPage = {
    page: 0,
    totalPage: 1,
    pageLimit: 20,
    totalNumber: 0
  };
  downdata = {
    level: 0,
    status: 0
  };
  titleList = [
    {name: 'VIP Level', key: 'vip_level'},
    {name: 'rp_username', key: 'userName'},
    {name: 'rp_level', key: 'level'},
    {name: 'btn_dep', key: 'deposit'},
    {name: 'btn_withdraw', key: 'withdraw'},
    {name: 'ui_text_016', key: 'winLose'},
    {name: 'rp_status', key: 'statusText'},
    {name: 'rp_operate', key: 'other'}
  ];
  status = [
    {
      value: 0,
      label: 'user_valid[0]'
    },
    {
      value: 1,
      label: 'user_valid[1]'
    },
    {
      value: 2,
      label: 'user_valid[2]'
    }
  ];
  levels = [
    {
      value: 0,
      label: 'user_valid[0]'
    },
    {
      value: 1,
      label: 'ui_agent'
    },
    {
      value: 2,
      label: 'ui_member'
    }
  ];
  value = '';

  goToLink(name: string) {
    this.$router.push({name});
  }
  showGameRecord(item: Iuser) {
    this.$router.push({
      name: 'MemberGameRecord',
      params: {
        id: item.userId + '-' + item.userName
      }
    });
  }
}
</script>
<style lang="scss" scoped>
.u_p--l10 {
  padding-left: 10px;
}
.u_p--r10 {
  padding-right: 10px;
}
.main-btn {
  width: 100%;
  margin: auto;
  height: 1.92rem;
  color: $cf;
  background-color: $bg;
  border-radius: 8px;
  font-size: 0.64rem;
  border-width: 0;
}
.static-container-header {
  margin-bottom: 30px;
}
.static-container-header--small .static-container-header__title {
  font-size: 24px;
  margin-bottom: 20px;
  text-transform: capitalize;
}

.el-form,
.panel {
  max-width: 95%;
}
.el-form-item--small {
  line-height: 20px;
  .el-form-item__content.plain {
    line-height: 38px;
  }
}
.el-form-item__label {
  text-align: right;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #000;
  line-height: 40px;
  padding: 0 6px 0 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.static-container-header__title::before {
  content: none;
  right: 34.3%;
  width: 70.3%;
}

.el-input--medium {
  height: 0.4rem;
  line-height: 0.4rem;
  vertical-align: text-top;
}
.el-form-item__content {
  line-height: 20px;
}
.nowrap {
  white-space: nowrap;
}

.el-button + .el-button {
  margin-left: 0;
}

:deep(.el-date-editor.el-input) {
  width: 340px;

  .el-input__inner::placeholder {
    color: #c0c4cc;
  }

  &.c_input--longest .el-input__inner:valid {
    border-color: transparent;
    color: #666666;
  }
  &.c_input--longest .el-input__inner {
    background-color: #f5f5f5;
  }
}
.reverse {
  display: flex;
  flex-direction: column-reverse;
}
.c_input--longest {
  width: 210px;
  border: 1px solid #c0c4cc;
  border-radius: 8px;
}
.u_m--t50 {
  margin-top: 50px;
}
.u_m--r20 {
  margin-right: 20px;
}
.u_m--r10 {
  margin-right: 10px;
}
.u_m--r12 {
  margin-right: 12px;
}
.u_m--l12 {
  margin-left: 12px;
}
.c_input--longest .el-input__inner:valid {
  border-color: transparent;
  color: #666666;
}
.c_input--longest :deep(.el-input__inner) {
  background-color: #f5f5f5;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.el-input--medium .el-input__inner {
  height: 36px;
  line-height: 36px;
}
.el-input__inner::placeholder {
  color: #c0c4cc;
}
.userbtn-bg--submit {
  background-color: $bg;
  width: calc(100% - 20px);
  font-weight: 700 !important;
}
.userbtn-bg--submit:hover {
  opacity: 0.5;
}
.userbtn-bg--submit:disabled {
  background-color: #e8e8e8;
  background-image: linear-gradient(to left, #e8e8e8, #e8e8e8);
  color: #999999;
  cursor: not-allowed;
}
[class*='c_button']:not(.c_button__text)[class*='--huge'] {
  font-size: 18px;
  line-height: 25px;
  padding: 4px 12px;
}
[class*='c_button']:not(.c_button__text) {
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  font: 14px 'Open Sans', 'Microsoft YaHei', 'Helvetica Neue', Helvetica, Arial, 'PingFang SC',
    'Hiragino Sans GB', 'Heiti SC', 'WenQuanYi Micro Hei', sans-serif;
  line-height: 19px;
  overflow: hidden;
  padding: 8px 25px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  -webkit-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}
[class*='c_button']:not(.c_button__text) .c_button__text {
  position: relative;
  z-index: 5;
}
.c_button__text {
  font-size: 12px;
}
.j_btn-txt--primary {
  color: #ffffff;
}
.u_m--l5 {
  margin-left: 5px;
}
.online_cs {
  color: #0047ab;
  text-decoration: underline;
}
.u_c--pointer {
  cursor: pointer;
}
.table-row {
  display: table-row;
  border: solid 1px #ff0000;
}

.table-cell {
  display: table-cell;
  vertical-align: middle;
  padding-top: 8px;
  padding-bottom: 8px;
  white-space: nowrap;
  min-width: 90px;
}
.el-form-item__content {
  line-height: 20px;
  position: relative;
  font-size: 14px;
}
.el-form-item__content::before,
.el-form-item__content::after {
  display: table;
  content: '';
}
.btn_size {
  height: 32px;
  width: 82px;
}
.u_main--btn {
  -webkit-box-shadow: 0px 3px transparent;
  box-shadow: 0px 3px transparent;
  font-size: 0.14rem;
  border-radius: 8px;
  background-color: #1f55f0;
  color: #ffffff;
  cursor: pointer;
}
.u_d--table {
  min-width: 80%;
  display: table;
}
.user-info__form {
  margin-top: 30px;
  width: 95%;
}

.dialog_main {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dialog_head {
  font-size: 24px;
  font-weight: 400;
}
.btn_search {
  font-size: 18px;
  line-height: 25px;
  padding: 4px 12px;
  min-width: 120px;
  background-color: #1f55f0;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  line-height: 19px;
  overflow: hidden;
  padding: 8px 25px;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  vertical-align: middle;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  max-height: 40px;
  color: #fff;
  margin-top: 40px;
}
// table
.u_th--bg {
  background-color: inherit;
  color: inherit;
  font-weight: bold;
  border-bottom: 1px solid #ccc;
}

.page_number {
  color: $c3;
}
.c_table {
  overflow: auto;
}
.c_table-fixed {
  z-index: 2;
}
table {
  table-layout: fixed;
  width: 100%;
  td,
  th {
    width: 9vw;
  }
  .th {
    position: relative;
    padding: 10px 0;
  }
  td:first-child,
  th:first-child {
    position: sticky;
    left: 0;
    z-index: 3;
  }
  th:first-child {
    background-image: $bg;
  }
  td:first-child {
    background-color: $bg !important;
    color: $btn-bg !important;
  }
}
.u_c--pointer {
  img {
    padding: 0 1px;
    width: 35px;
    height: 35px;
  }
}
</style>
